import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaEbay } from 'react-icons/fa';
import './App.css';
import logo from './logo.png'; // Ensure you have the logo image in the src folder

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1 className="website-name">www.bargbal.com</h1>
        <img src={logo} alt="Business Logo" className="logo" />
        <h2>Coming Soon</h2>
        <p>We are excited to be working on the ultimate destination for online retail, consultancy, and catering services for you. Stay tuned for our big launch!</p>
        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="https://www.ebay.com" target="_blank" rel="noopener noreferrer" className="ebay-icon"><FaEbay /></a>
        </div>
      </header>
    </div>
  );
}

export default App;
